
import "./JsonResponse.css"
const JsonResponseOutput = (props) =>{

    return <div className="response">
        <div>
            <span className="textFamilyMessage">{props.message}</span>
        </div>
        {props.output}
    </div>
}

export default JsonResponseOutput