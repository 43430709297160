import "./RegisterTppPortal.css"

const RegisterTppPortal = () => {
    return <div id="tppId" className="description">
        <h1>Register as TPP in developer portal</h1>
        <p className="text-content">
            Once you successfully register your company on WITTY developer portal, you become our partner.
            Here you are allowed to enclose technical details and instruction how to start using WITTY Open Banking API.

            To be a Developer please send request for credentials. After successed authorization you receive full access right to WITTY open API.
        </p>
    </div>
}

export default RegisterTppPortal