import "./PeriodicCancelRequest.css";

let api = "/api/periodic-payments/{payment-product}/{paymentId}";

const requestType = <button className="status200">DELETE</button>;

const PeriodicCancelRequest = (props) => {
  return (
    <div id="periodiccancel" className="balance">
      <div>
        <span className="textFamily">Request for Payment Cancellation</span>
      </div>
      <div>
        <br />
        <span className="textTricks"> Request parameters: </span>
      </div>
      <button className="but">
        {requestType} {api}
      </button>
      <br />
      <br />
      <div>
        <span className="textFamily">Headers: </span>
      </div>
      <br />
      <table>
        <tbody>
          <tr>
            <th>Header</th>
            <th>Value</th>
          </tr>
          <tr>
            <td>Authorization Code</td>
            <td>Basic base64encoded(clientId:clientSecret)</td>
          </tr>
          <tr>
            <td>Content-Type</td>
            <td>application/json</td>
          </tr>
        </tbody>
      </table>
      <br />
      <div>
        <span className="textFamily">
          REQUEST BODY SCHEMA: application/json
        </span>
      </div>
      <br />
      <table>
        <tbody>
          <tr>
            <th>Parameter</th>
            <th>Description</th>
          </tr>
          <tr>
            <td>
              payment-product <span style={{ color: "red" }}>*required</span>
            </td>
            <td>Name of payment product</td>
          </tr>
          <tr>
            <td>
              paymentId <span style={{ color: "red" }}>*required</span>
            </td>
            <td>Unique identifier of payment</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default PeriodicCancelRequest;
