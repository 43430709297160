import './App.css';
import Layout from './components/layout/Layout';
import Home from './components/UI/home/Home';
import Login from './components/UI/login/Login';
import Register from './components/UI/register/Register';
import { Switch, Route, Redirect } from 'react-router-dom';
import ViewLayout from './components/UI/view-layout/ViewLayout';
import { useContext } from 'react';
import AuthContext from './store/auth-context';
import Success from './components/UI/register/Success';

function App() {
  const authCtx = useContext(AuthContext)

  return (

    <ViewLayout>
      <Switch>
        <Route path='/' exact>
          <Home />
        </Route>
        <Route path='/login'>
          <Login />
        </Route>
        <Route path='/register'>
          <Register />
        </Route>
        <Route path='/success'>
          <Success />
        </Route>
        <Route path="/layout">
          {authCtx.isLoggedIn && <Layout />}
          {!authCtx.isLoggedIn && <Redirect to="/" />}
        </Route>

        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
    </ViewLayout>
  );
}

export default App;
