import React from 'react';

import classes from './ConfirmModal.module.css';
import Card from './Card';
import Button from './Button';
import { useHistory } from 'react-router-dom';

const ConfirmModal = (props) => {
  const history = useHistory()
  const loadRegisterHandler = () => {
    history.push('/')
  }
  return (
    <div>
      <div className={classes.backdrop} onClick={props.onConfirm} />
      <Card className={classes.modal}>
        <header className={classes.header}>
          <h2>Registration confirmation </h2>
        </header>
        <div className={classes.content}>
          <p>Register with an email ID : {props.email}</p>
        </div>
        <footer className={classes.actions}>
          <div className={classes.alignhorizental}>
            <div>
              <Button onClick={props.onConfirm}>Yes</Button>
            </div>
            <div>
              <Button onClick={loadRegisterHandler}>No</Button>
            </div>
          </div>
        </footer>
      </Card>
    </div>
  );
};

export default ConfirmModal;
