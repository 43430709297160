import data from "./status.json"
import { useState } from "react"
import data400 from "./status_400.json"
import data500 from "./status_500.json"
import JsonResponseOutput from "../../../../jsonResponses/JsonResponseOutput"


let api = "/api/bulk-payments/{payment-product}/{paymentId}/status"

const successJson = <div><pre>{JSON.stringify(data, null, 2)}</pre></div>;
const failureJson = <div><pre>{JSON.stringify(data400, null, 2)}</pre></div>;
const internalServerErrorJson = <div><pre>{JSON.stringify(data500, null, 2)}</pre></div>;

const StatusResponse = () => {

    const [statusCode, setStatusCode] = useState('200')

    const successStatusHandler = () => {
        setStatusCode('200')
    }

    const failureStatusHandler = () => {
        setStatusCode('400')
    }

    const internalServerErroStatusHandler = () => {
        setStatusCode('500')
    }

    const requestType = <button className="status200">GET</button>
    return <div className="right-content mb100">
        <div>
            <span className="textTricks">Request URL: </span>
        </div>
        <button className="but">{requestType} {api}</button>
        <div>
            <span>
                <br></br>
            </span>
            <span className="textTricks">Response -</span>
        </div>
        <div>
            <span></span>
        </div>
        <div className="alignment">
            <button className="status200" onClick={successStatusHandler}>200</button>
            <button className="status400" onClick={failureStatusHandler}>400</button>
            <button className="status500" onClick={internalServerErroStatusHandler}>500</button>
        </div>
        <div>
            {statusCode === '200' && <JsonResponseOutput output={successJson} message={"Transaction Successful"} />}
            {statusCode === '400' && <JsonResponseOutput output={failureJson} message={"Validation Failure"} />}
            {statusCode === '500' && <JsonResponseOutput output={internalServerErrorJson}
                message={"Internal Server Error"} />}
        </div>
    </div>
}

export default StatusResponse