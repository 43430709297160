import "./ConsentsGetDetailsReq.css";

let api = "/api/consents/{consentId}";
const requestType = <button className="status200">GET</button>;

const ConsentsGetDetailsReq = (props) => {
  return (
    <div id="consents-details-id" className="balance">
      <div>
        <span className="textFamily">
          This method returns the details of the consents on the basis of
          consent id.
        </span>
      </div>
      <div>
        <br />
        <span className="textTricks"> Request parameters: </span>
      </div>
      <button className="but">
        {requestType} {api}
      </button>
      <br />
      <br />
      <div>
        <span className="textFamily">Headers: </span>
      </div>
      <br />
      <table>
        <tbody>
          <tr>
            <th>Header</th>
            <th>Value</th>
          </tr>
          <tr>
            <td>Authorization Code</td>
            <td>Basic base64encoded(clientId:clientSecret)</td>
          </tr>
          <tr>
            <td>Content-Type</td>
            <td>application/json</td>
          </tr>
        </tbody>
      </table>
      <br />
      <div>
        <span className="textFamily">
          REQUEST BODY SCHEMA: application/json
        </span>
      </div>
      <br />
      <table>
        <tbody>
          <tr>
            <th>Parameter</th>
            <th>Description</th>
          </tr>
          <tr>
            <td>
              consentId <span style={{ color: "red" }}>*required</span>
            </td>
            <td>NA</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ConsentsGetDetailsReq;
