import "./RevokeConsentId.css";

let api = "/api/external/v1/obs/revoke-consent/{consentId}";

const requestType = <button className="status200">POST</button>;

const RevokeConsentId = (props) => {
  return (
    <div id="revokeconsentid" className="balance">
      <div>
        <span className="textFamily"></span>
      </div>
      <div>
        <span className="textTricks"> Request parameters: </span>
      </div>
      <button className="but">
        {requestType} {api}
      </button>
      <br />
      <br />
      <div>
        <span className="textFamily">Headers: </span>
      </div>
      <br />
      <table>
        <tbody>
          <tr>
            <th>Header</th>
            <th>Value</th>
          </tr>
          <tr>
            <td>Authorization Code</td>
            <td>Basic base64encoded(clientId:clientSecret)</td>
          </tr>
          <tr>
            <td>Content-Type</td>
            <td>application/json</td>
          </tr>
        </tbody>
      </table>
      <br />
      <div>
        <span className="textFamily">
          REQUEST BODY SCHEMA: application/json
        </span>
      </div>
      <br />
      <table>
        <tbody>
          <tr>
            <th>Parameter</th>
            <th>Description</th>
          </tr>
          <tr>
            <td>NA</td>
            <td>NA</td>
          </tr>
        </tbody>
      </table>

      <div className="response-container">
        <h3 className="textTricks">Responses</h3>
        <p>200 OK</p>
        <p>400 Bad Request</p>
        <p>401 Unauthorized</p>
        <p>500 Internal server error</p>
      </div>
    </div>
  );
};

export default RevokeConsentId;
