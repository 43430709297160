import React, { useState } from "react";
import "./ContentToLeft.css";
import { IoArrowForward } from "react-icons/io5";
import { FiTool } from "react-icons/fi";
import { AiOutlineApi } from "react-icons/ai";
import { BiChevronDown } from "react-icons/bi";
import {
  RiBankFill,
  RiRegisteredFill,
  RiErrorWarningLine,
} from "react-icons/ri";
import { MdVpnKey } from "react-icons/md";
import { SiWebauthn } from "react-icons/si";
import { SiStrapi } from "react-icons/si";
import { GiSausagesRibbon } from "react-icons/gi";

const ContentToLeft = (props) => {
  // const [offset, setOffset] = useState(0);

  // let periodicDetails = false
  // useEffect(() => {
  //   window.onscroll = () => {
  //     setOffset(window.pageYOffset)
  //   }
  // }, []);

  // console.log(offset)

  // if(offset > 11212 && offset < 11808){
  //    periodicDetails = true
  // }
  const [isValid, setIsValid] = useState(false);
  const [isApiValid, setIsApiValid] = useState(false);
  const [isOpbValid, setIsOpbValid] = useState(false);
  const [isAuthValid, setIsAuthValid] = useState(false);
  const [isPispValid, setIsPispValid] = useState(false);
  const [isRevokeValid, setIsRevokeValid] = useState(false);

  const listOfPISPHandler = () => {
    if (isApiValid === true) {
      setIsApiValid(false);
    }
    setIsPispValid(!isPispValid);
  };
  const dropDownHandler = () => {
    if (isPispValid === true) {
      setIsPispValid(false);
    }
    if (isApiValid === true) {
      setIsApiValid(false);
    }

    if (isAuthValid === true) {
      setIsAuthValid(!isAuthValid);
    }
    if (isRevokeValid === true) {
      setIsRevokeValid(false);
    }
    setIsValid(!isValid);
  };

  const listOfApiHandler = () => {
    setIsApiValid(!isApiValid);
    if (isPispValid === true) {
      setIsPispValid(false);
    }
  };

  const openBankingAPIsHandler = () => {
    setIsOpbValid(!isOpbValid);
  };

  const listOfAuthorizationApiHandler = () => {
    setIsAuthValid(!isAuthValid);
    if (isOpbValid === true) {
      setIsOpbValid(!isOpbValid);
    }
  };

  const listOfRevokeEndApis = () => {
    setIsRevokeValid(!isRevokeValid);
    if (isPispValid === true) {
      setIsPispValid(false);
    }
    if (isApiValid === true) {
      setIsApiValid(false);
    }

    if (isAuthValid === true) {
      setIsAuthValid(!isAuthValid);
    }
  };
  return (
    <div>
      <ul className="left">
        <li>
          {" "}
          <a href="#introId">
            <span>
              <IoArrowForward /> Introduction to the Open Banking API
            </span>
          </a>
        </li>
        <li>
          {" "}
          <a href="#guideId">
            <span onClick={openBankingAPIsHandler}>
              <RiBankFill /> Open Banking integration Guide <BiChevronDown />
            </span>
          </a>
        </li>
        <div className="textleftmove">
          {isOpbValid && (
            <li>
              <a href="#tppId">
                {" "}
                <span>
                  <RiRegisteredFill /> Register as TPP in developer portal
                </span>
              </a>
            </li>
          )}
          {isOpbValid && (
            <li>
              {" "}
              <a href="#requestcredid">
                <span>
                  <MdVpnKey /> Request Credentials
                </span>
              </a>
            </li>
          )}
          {isOpbValid && (
            <li>
              {" "}
              <a href="#consumeaisppisp">
                <span>
                  <MdVpnKey /> Consume AISP and PISP
                </span>
              </a>
            </li>
          )}
          {isOpbValid && (
            <li>
              {" "}
              <a href="#sandbox">
                <span>
                  <MdVpnKey /> Production and sandbox hosts
                </span>
              </a>
            </li>
          )}
        </div>
        <li>
          <span onClick={dropDownHandler}>
            <AiOutlineApi /> API Reference <BiChevronDown />
          </span>
        </li>
        <div className="textToMoveMain">
          {isValid && (
            <li>
              {" "}
              <span onClick={listOfAuthorizationApiHandler}>
                <SiWebauthn /> Authorization endpoints <BiChevronDown />
              </span>
            </li>
          )}
        </div>
        <div className="textleftmove">
          {isAuthValid && (
            <li>
              <a href="#authinitiate">
                <span>
                  <button>POST</button> /api/auth-initiate
                </span>
              </a>
            </li>
          )}
          {isAuthValid && (
            <li>
              <a href="#authverify">
                <span>
                  <button>POST</button> /api/auth-verify
                </span>
              </a>
            </li>
          )}
          {isAuthValid && (
            <li>
              <a href="#resendotp">
                <span>
                  <button>POST</button> /api/resend-otp
                </span>
              </a>
            </li>
          )}
        </div>
        <div className="textToMoveMain">
          {isValid && (
            <li>
              {" "}
              <span onClick={listOfApiHandler}>
                <SiStrapi /> AISP endpoints <BiChevronDown />
              </span>
            </li>
          )}
        </div>
        <div className="textleftmove">
          {isApiValid && (
            <li>
              <a href="#balanceId">
                <span>
                  <button>GET</button> /api/balances
                </span>
              </a>
            </li>
          )}
          {isApiValid && (
            <li>
              <a href="#transactionId">
                <span>
                  <button>GET</button> /api/transactions
                </span>
              </a>
            </li>
          )}
          {isApiValid && (
            <li>
              <a href="#accountsId">
                <span>
                  <button>GET</button> /api/accounts
                </span>
              </a>
            </li>
          )}
          {isApiValid && (
            <li>
              <a href="#accounts-id">
                <span>
                  <button>GET</button> /api/accounts/id
                </span>
              </a>
            </li>
          )}
          {isApiValid && (
            <li>
              <a href="#consents-status">
                <span>
                  <button>GET</button> /api/consents/status
                </span>
              </a>
            </li>
          )}
          {isApiValid && (
            <li>
              <a href="#consents-details-id">
                <span>
                  <button>GET</button> /api/consents/details
                </span>
              </a>
            </li>
          )}
        </div>
        <div className="textToMoveMain">
          {isValid && (
            <li>
              {" "}
              <span onClick={listOfPISPHandler}>
                <SiStrapi /> PISP endpoints <BiChevronDown />
              </span>
            </li>
          )}
        </div>
        <div className="textleftmove">
          {isPispValid && (
            <li>
              <a href="#bulkstatusid">
                <span>
                  <button>GET</button> /api/bulk-payments/status
                </span>
              </a>
            </li>
          )}
          {isPispValid && (
            <li>
              <a href="#bulkdetailsId">
                <span>
                  <button>GET</button> /api/bulk-payments/details
                </span>
              </a>
            </li>
          )}
          {isPispValid && (
            <li>
              <a href="#paymentstatusId">
                <span>
                  <button>GET</button> /api/payments/product/status
                </span>
              </a>
            </li>
          )}
          {isPispValid && (
            <li>
              <a href="#paymentDetailsId">
                <span>
                  <button>GET</button> /api/payments/product/paymentId
                </span>
              </a>
            </li>
          )}
          {isPispValid && (
            <li>
              <a href="#paymentcancel">
                <span>
                  <button>DELETE</button> /api/payments/product/cancel
                </span>
              </a>
            </li>
          )}
          {isPispValid && (
            <li>
              <a href="#paymentcreate">
                <span>
                  <button>POST</button> /api/payments/products/create
                </span>
              </a>
            </li>
          )}
          {isPispValid && (
            <li>
              <a href="#periodicstatus">
                <span>
                  <button>GET</button> /api/periodic-payments/status
                </span>
              </a>
            </li>
          )}
          {isPispValid && (
            <li>
              <a href="#periodicdetails">
                <span>
                  <button>GET</button> /api/periodic-payments/details
                </span>
              </a>
            </li>
          )}
          {isPispValid && (
            <li>
              <a href="#periodiccancel">
                <span>
                  <button>DELETE</button> /api/periodic-payments/cancel
                </span>
              </a>
            </li>
          )}
        </div>
        <div className="textToMoveMain">
          {isValid && (
            <li>
              {" "}
              <span onClick={listOfRevokeEndApis}>
                <SiStrapi /> Revoke consent endpoints <BiChevronDown />
              </span>
            </li>
          )}
        </div>
        <div className="textleftmove">
          {isRevokeValid && (
            <li>
              <a href="#revokeconsents">
                <span>
                  <button>POST</button> /api/revoke-consents
                </span>
              </a>
            </li>
          )}
          {isRevokeValid && (
            <li>
              <a href="#revokeconsentid">
                <span>
                  <button>POST</button> /api/revoke-consent/id
                </span>
              </a>
            </li>
          )}
        </div>
        <div className="textToMoveMain">
          {isValid && (
            <div>
              <li>
                <a href="#errors">
                  <RiErrorWarningLine />
                  <span> Errors</span>
                </a>
              </li>
              <li>
                <a href="#usagelimits">
                  <span>
                    <GiSausagesRibbon /> Usage and limits
                  </span>
                </a>
              </li>
            </div>
          )}
        </div>

        <li>
          {" "}
          <a href="#glossary">
            <span>
              <FiTool /> Glossary
            </span>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default ContentToLeft;
