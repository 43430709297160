import data from "./cancel.json"
import { useState } from "react"
import data400 from "./cancel400.json"
import data500 from "./cancel500.json"
import data202 from "./cancel202.json"
import data204 from "./cancek204.json"
import JsonResponseOutput from "../../../../../jsonResponses/JsonResponseOutput"

let api = "/api/periodic-payments/{payment-product}/{paymentId}"

const successJson = <div><pre>{JSON.stringify(data, null, 2)}</pre></div>;
const failureJson = <div><pre>{JSON.stringify(data400, null, 2)}</pre></div>;
const internalServerErrorJson = <div><pre>{JSON.stringify(data500, null, 2)}</pre></div>;
const noAuthJson = <div><pre>{JSON.stringify(data202, null, 2)}</pre></div>;
const noResponseBody = <div><pre>{JSON.stringify(data204, null, 2)}</pre></div>;

const PeriodicCancelResponse = () => {

    const [statusCode, setStatusCode] = useState('200')

    const successStatusHandler = () => {
        setStatusCode('200')
    }

    const failureStatusHandler = () => {
        setStatusCode('400')
    }

    const internalServerErroStatusHandler = () => {
        setStatusCode('500')
    }

    const authNeededHandler = () => {
        setStatusCode('202')
    }

    const noResponseHandler = () => {
        setStatusCode('204')
    }

    const requestType = <button className="status200">DELETE</button>
    return <div className="right-content">
        <div>
            <span className="textTricks">Request URL: </span>
        </div>
        <button className="but">{requestType} {api}</button>
        <div>
            <span>
                <br></br>
            </span>
            <span className="textTricks">Response -</span>
        </div>
        <div>
            <span></span>
        </div>
        <div className="alignment">
            <button className="status200" onClick={successStatusHandler}>200</button>
            <button className="status200" onClick={authNeededHandler}>202</button>
            <button className="status200" onClick={noResponseHandler}>204</button>
            <button className="status400" onClick={failureStatusHandler}>400</button>
            <button className="status500" onClick={internalServerErroStatusHandler}>500</button>
        </div>
        <div>
            {statusCode === '200' && <JsonResponseOutput output={successJson} message={"Transaction Successful"} />}
            {statusCode === '400' && <JsonResponseOutput output={failureJson} message={"Validation Failure"} />}
            {statusCode === '500' && <JsonResponseOutput output={internalServerErrorJson}
                message={"Internal Server Error"} />}
            {statusCode === '202' && <JsonResponseOutput output={noAuthJson}
                message={"Request Accepted But Authorization Needed"} />}
            {statusCode === '204' && <JsonResponseOutput output={noResponseBody}
                message={"Request Successful But No Responce Body"} />}
        </div>
    </div>
}

export default PeriodicCancelResponse