import React, { Fragment, useContext } from "react"
import { useHistory } from "react-router"
import logout from "../../../static/assets/logout.png"
import AuthContext from "../../../store/auth-context"
import "./Header.css"
import { useIdleTimer } from 'react-idle-timer'

const Header = () => {
    const history = useHistory()
    const authCtx = useContext(AuthContext)
    const logoutHandler = () => {
        authCtx.logout()
        history.replace('/')
    }

    const handleOnIdle = () => {
        console.log('last active', getLastActiveTime())
        logoutHandler();
    };
      
    const { getLastActiveTime } = useIdleTimer({
        timeout: 1000 * 60 * 15,
        onIdle: handleOnIdle,
        debounce: 500
    });
   
    return (
        <Fragment>
            <div className="float-container">
                <div className="float-child">
                    <span style={{ fontWeight:"bold",color :"#05386B" }}>W1TTY Developer</span>
                </div>
                <div className="float-child">
                    <img src={logout} alt="logout" onClick={logoutHandler} />
                </div>
            </div>

        </Fragment>
    )
}

export default Header