

const ObIntegrationGude = () => {
    return (
        <div id="guideId" className="description">
            <h1>Open Banking Integration Guide</h1>
            <p>
                To start your open banking API, you must register your application as TPP developer. In 
                addition you need to setup your sandbox environment to test your integration.
            </p>
        </div>
    )
}

export default ObIntegrationGude