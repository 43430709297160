import "./UsageAndLimitis.css"

const UsageAndLimits = () => {
    return (
        <div id="usagelimits" className="balance">
            <span className="textFamily">
                In order to ensure safe and fair resource sharing, we define the following usage limits:

                A maximum of 100 requests per second for one application.
                Please make sure to stay under this limit. Should you estimate this limit to be a problem for your use case of our API, please reach out to us directly.
            </span>
        </div>
    )
}

export default UsageAndLimits