import "./Glossary.css"

const Glossary = () => {

    return (
        <div id="glossary" className="balance">
            <table>
                <tbody>
                    <tr>
                        <th>Name</th>
                        <th>Term</th>
                        <th>Definition</th>
                    </tr>
                    <tr>
                        <td>TTP</td>
                        <td>Third Party Provider</td>
                        <td>Third Party Providers are organisations or natural persons that use APIs developed to Standards to access customer’s accounts, in order to provide account information services and/or to initiate payments. Third Party Providers are either/both Payment Initiation Service Providers (PISPs) and/or Account Information Service Providers (AISPs).</td>
                    </tr>
                    <tr>
                        <td>AISP</td>
                        <td>Account Information Service Provider</td>
                        <td>An Account Information Service provides account information services as an online service to provide consolidated information on one or more payment accounts held by a payment service user with one or more payment service provider(s).</td>
                    </tr>
                    <tr>
                        <td>ASPSP</td>
                        <td>Account Servicing Payment Service Provider</td>
                        <td>Account Servicing Payment Service Providers provide and maintain a payment account for a payer as defined by the PSRs and, in the context of the Open Banking Ecosystem are entities that publish Read/Write APIs to permit, with customer consent, payments initiated by third party providers and/or make their customers’ account transaction data available to third party providers via their API end points.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default Glossary