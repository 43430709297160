

import classes from "./Sandbox.module.css"

const Sandbox = ()=>{
    return (
        <div id="sandbox" className={classes.sandbox}>
            <div>
                <span className={classes.spacing}>Production and Sandbox hosts</span>
                <br/>
                <br/>
            </div>
            <div>
                <span className={classes.spacingtext}>- For sandbox testing use: </span>
                <br/>
                <br/>
            </div>
            <div>
                <span className={classes.spacingtext}>https://sandbox.obapi.w1tty.com</span>
                <br/>
                <br/>
            </div>
            <div>
                <span className={classes.spacingtext}>- For production use: </span>
                <br/>
                <br/>
            </div>
            <div>
                <span className={classes.spacingtext}>https://prd.obapi.w1tty.com</span>
                <br></br>
                <br/>
            </div>
        </div>
    )
}

export default Sandbox