import "./PaymentCreateRequest.css";
import request_data from "./request.json";
import JsonRequests from "../../../../../jsonRequests/JsonRequests";

const requestJsonBody = (
  <div>
    <pre>{JSON.stringify(request_data, null, 2)}</pre>
  </div>
);
let api = "/api/payments/{payment-product}";

const requestType = <button className="status200">POST</button>;

const PaymentCreateRequest = (props) => {
  return (
    <div id="paymentcreate" className="balance mb150">
      <div>
        <span className="textFamily">
          Create the payment
        </span>
      </div>
      <div>
        <br />
        <span className="textTricks"> Request parameters: </span>
      </div>
      <button className="but">
        {requestType} {api}
      </button>
      <br />
      <br />
      <div>
        <span className="textFamily">Headers: </span>
      </div>
      <br />
      <table>
        <tbody>
          <tr>
            <th>Header</th>
            <th>Value</th>
          </tr>
          <tr>
            <td>Authorization Code</td>
            <td>Basic base64encoded(clientId:clientSecret)</td>
          </tr>
          <tr>
            <td>Content-Type</td>
            <td>application/json</td>
          </tr>
        </tbody>
      </table>
      <br />
      <div>
        <span className="textFamily">
          Details required to create a payment
        </span>
      </div>
      <br />
      <div className="textFont">
        <JsonRequests output={requestJsonBody} />
      </div>

        <div className="response-container">
          <h3 className="textTricks">Responses</h3>
          <p>200 OK</p>
          <p>400 Bad Request</p>
          <p>401 Unauthorized</p>
          <p>500 Internal server error</p>
        </div>
    </div>
  );
};

export default PaymentCreateRequest;
