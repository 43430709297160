import "./Description.css"

const Description = () => {
    return <div id="introId" className="description">
        <div className="textAdjustment">
            <h1>Introduction to the Open Banking API</h1>
            <p>
            The Witty Open Banking API is the gateway for third-party providers to interact with Witty customers and products.
            You can take advantage of the Open Banking API to build your banking applications whether you are
           - A regulated third party provider that wants to get account and transaction information of Witty customers and initiate different types of payments, or 
           - An innovative service provider that is looking to seamlessly integrate Witty functions into your own applications. 
           You must become our Witty partner to get access to our API.
           Related Links:
           Open Banking API Reference
           
                   </p>
        </div>
    </div>
}

export default Description