import React, { Fragment } from 'react'

const ViewLayout =(props)=>{
    return (
        <Fragment>
          <main>{props.children}</main>
        </Fragment>
      );
}

export default ViewLayout