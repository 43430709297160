
import "./JsonRequests.css"
const JsonRequests = (props) =>{

    return <div className="request">
        <div>
            <span className="textFamilyMessage">{props.message}</span>
        </div>
        {props.output}
    </div>
}

export default JsonRequests