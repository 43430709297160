import "./StatusRequest.css";

let api = "/api/bulk-payments/{payment-product}/{paymentId}/status";
const requestType = <button className="status200">GET</button>;

const StatusRequest = (props) => {
  return (
    <div id="bulkstatusid" className="balance mb100">
      <div>
        <span className="textFamily">
          Fetches the status of a bulk payment. Status of a transfer is fetched
          on the basis of the name of payment product and unique payment id.
        </span>
      </div>
      <div>
        <br />
        <span className="textTricks"> Request parameters: </span>
      </div>
      <button className="but">
        {requestType} {api}
      </button>
      <br />
      <br />
      <div>
        <span className="textFamily">Headers: </span>
      </div>
      <br />
      <table>
        <tbody>
          <tr>
            <th>Header</th>
            <th>Value</th>
          </tr>
          <tr>
            <td>Authorization Code</td>
            <td>Basic base64encoded(clientId:clientSecret)</td>
          </tr>
          <tr>
            <td>Content-Type</td>
            <td>application/json</td>
          </tr>
        </tbody>
      </table>
      <br />
      <div>
        <span className="textFamily">
          REQUEST BODY SCHEMA: application/json
        </span>
      </div>
      <br />
      <table>
        <tbody>
          <tr>
            <th>Parameter</th>
            <th>Description</th>
          </tr>
          <tr>
            <td>
              payment-product <span style={{ color: "red" }}>*required</span>
            </td>
            <td>Name of payment product</td>
          </tr>
          <tr>
            <td>
              paymentId <span style={{ color: "red" }}>*required</span>
            </td>
            <td>Unique identifier of payment</td>
          </tr>
        </tbody>
      </table>

      <div className="response-container">
        <h3 className="textTricks">Responses</h3>
        <p>200 OK</p>
        <p>400 Bad Request</p>
        <p>401 Unauthorized</p>
        <p>500 Internal server error</p>
      </div>
    </div>
  );
};

export default StatusRequest;
