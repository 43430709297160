import data from "./consentid.json";
import { useState } from "react";
import JsonResponseOutput from "../../../../jsonResponses/JsonResponseOutput";

let api = "/api/external/v1/obs/revoke-consent/{consentId}";

const successJson = (
  <div>
    <pre>{JSON.stringify(data, null, 2)}</pre>
  </div>
);

const ConsentsIdResponse = () => {
  const [statusCode, setStatusCode] = useState("200");

  const successStatusHandler = () => {
    setStatusCode("200");
  };

  const requestType = <button className="status200">POST</button>;
  return (
    <div className="right-content">
      <div>
        <span className="textTricks">Request URL: </span>
      </div>
      <button className="but">
        {requestType} {api}
      </button>
      <div>
        <span>
          <br></br>
        </span>
        <span className="textTricks">Response -</span>
      </div>
      <div>
        <span></span>
      </div>
      <div className="alignment">
        <button className="status200" onClick={successStatusHandler}>
          200
        </button>
      </div>
      <div>
        {statusCode === "200" && (
          <JsonResponseOutput output={successJson} message={null} />
        )}
      </div>
    </div>
  );
};

export default ConsentsIdResponse;
