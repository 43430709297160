import "./AuthVerifyRequest.css";
import request_data from "./request.json";
import JsonRequests from "../../../../jsonRequests/JsonRequests";

let api = "/api/external/v1/obs/auth-verify";

const successJson = (
  <div>
    <pre>{JSON.stringify(request_data, null, 2)}</pre>
  </div>
);
const requestType = <button className="status200">POST</button>;

const AuthVerifyRequest = (props) => {
  return (
    <div id="authverify" className="balance mb100">
      <div>
        <span className="textTricks"> Request parameters: </span>
      </div>
      <button className="but">
        {requestType} {api}
      </button>
      <br />
      <br />
      <div>
        <span className="textFamily">Headers: </span>
      </div>
      <br />
      <table>
        <tbody>
          <tr>
            <th>Header</th>
            <th>Value</th>
          </tr>
          <tr>
            <td>Authorization Code</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Content-Type</td>
            <td>application/json</td>
          </tr>
        </tbody>
      </table>
      <br />
      <div>
        <span className="textFamily">
          REQUEST BODY SCHEMA: application/json
        </span>
      </div>
      <br />
      <div className="textFont">
        <JsonRequests output={successJson} />
      </div>
      <br />
      <div>
        <span className="textTricks">Responses</span>
      </div>
      <br />
      <div>
        <span>200 OK</span>
      </div>
      <br />
      <div>
        <span>400 Bad Request</span>
      </div>
      <br />
      <div>
        <span>401 Unauthorized</span>
      </div>
      <br />
      <div>
        <span>500 Internal server error</span>
      </div>
    </div>
  );
};

export default AuthVerifyRequest;
