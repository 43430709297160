import "./Transaction.css";

let api = "/api/accounts/{account-id}/transactions";
const requestType = <button className="status200">GET</button>;

const Transaction = (props) => {
  return (
    <div id="transactionId" className="balance mb150">
      <div>
        <span className="textFamily">
          This method fetches the transactions of a bank account for different
          specified criteria like From Date to To Date or last Number Of
          Transactions.
        </span>
      </div>
      <div>
        <br />
        <span className="textTricks"> Request parameters: </span>
      </div>
      <button className="but">
        {requestType} {api}
      </button>
      <br />
      <br />
      <div>
        <span className="textFamily">Headers: </span>
      </div>
      <br />
      <table>
        <tbody>
          <tr>
            <th>Header</th>
            <th>Value</th>
          </tr>
          <tr>
            <td>Authorization Code</td>
            <td>Basic base64encoded(clientId:clientSecret)</td>
          </tr>
          <tr>
            <td>Content-Type</td>
            <td>application/json</td>
          </tr>
        </tbody>
      </table>
      <br />
      <div>
        <span className="textFamily">
          REQUEST BODY SCHEMA: application/json
        </span>
      </div>
      <br />
      <table>
        <tbody>
          <tr>
            <th>Parameter</th>
            <th>Description</th>
          </tr>
          <tr>
            <td>
              account-id <span style={{ color: "red" }}>*required</span>
            </td>
            <td>fetches response according to account id</td>
          </tr>
          <tr>
            <td>dateFrom</td>
            <td>fetches response according to Booking Date</td>
          </tr>
          <tr>
            <td>dateFrom</td>
            <td>fetches response according to Booking Date</td>
          </tr>
          <tr>
            <td>entryReferenceFrom</td>
            <td>
              Get all transactions after the transaction with entryReferenceFrom
            </td>
          </tr>
          <tr>
            <td>
              bookingStatus <span style={{ color: "red" }}>*required</span>
            </td>
            <td>fetches response according to the booking status</td>
          </tr>
          <tr>
            <td>deltaList</td>
            <td>fetches all trnasaction after the last report access</td>
          </tr>
          <tr>
            <td>withBalance</td>
            <td>fetches response including booking balance</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Transaction;
