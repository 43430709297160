import "./Errors.css"

const Errors = ()=>{
    return (
        <div id="errors" className="balance">
            <br/>
            <h5>ERRORS</h5>
            <br/>
            <span className="textFamily">Http Response Errors</span>
            <br/>
            <br/>
            <span className="textFamily">The API used the following error codes</span>
            <table>
                <tr>
                    <th>Code</th>
                    <th>Meaning</th>
                </tr>
                <tr>
                    <td>400</td>
                    <td>Bad Request -- Your request is invalid.</td>
                </tr>
                <tr>
                    <td>401</td>
                    <td>Unauthorized -- Your access token is wrong.</td>
                </tr>
                <tr>
                    <td>403</td>
                    <td>Forbidden -- Access to the requested resource or action is forbidden.</td>
                </tr>
                <tr>
                    <td>404</td>
                    <td>Not Found -- The requested resource could not be found.</td>
                </tr>
                <tr>
                    <td>405</td>
                    <td>Method Not Allowed -- You tried to access an endpoint with an invalid method.</td>
                </tr>
                <tr>
                    <td>406</td>
                    <td>Not Acceptable -- You requested a format that isn't JSON.</td>
                </tr>
                <tr>
                    <td>429</td>
                    <td>Too Many Requests -- You're sending too many requests.</td>
                </tr>
                <tr>
                    <td>500</td>
                    <td>Internal Server Error -- We had a problem with our server. Try again later.</td>
                </tr>
                <tr>
                    <td>503</td>
                    <td>Service Unavailable -- We're temporarily offline for maintenance. Please try again later.</td>
                </tr>

            </table>
        </div>
    )
}

export default Errors