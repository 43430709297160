import React, { Fragment } from "react";
import { Grid } from "semantic-ui-react";
import ContentToLeft from "../pages/ContentLeft/ContentToLeft";
import AccountIdRequest from "../pages/description-content/APIPages/accountIdRequest/AccountIdRequest";
import Accounts from "../pages/description-content/APIPages/accountsRequest/Accounts";
import Balance from "../pages/description-content/APIPages/balancesRequest/Balance";
import ConsentStatusReq from "../pages/description-content/APIPages/consents/ConsentsStatusReq";
import ConsentsGetDetailsReq from "../pages/description-content/APIPages/consentsGetDetails/ConsentsGetDetailsReq";
import Transaction from "../pages/description-content/APIPages/transactionRequest/Transaction";
import Description from "../pages/description-content/Description";
import ObIntegrationGude from "../pages/description-content/obintergrationguide/ObIntegrationGude";
import RequestCredentials from "../pages/description-content/requestcredentials/RequestCredentials";
import RegisterTppPortal from "../pages/description-content/ttpportal/RegisterTppPortal";
import AccountIdRes from "../pages/response-pages/accountId/AccountIdRes";
import AccountRes from "../pages/response-pages/accounts/AccountRes";
import BalanceRes from "../pages/response-pages/balance/BalanceRes";
import ConsentGetDetailsRes from "../pages/response-pages/consentDetails/ConsentGetDetailsRes";
import ConsentsStatusRes from "../pages/response-pages/consentsStatus/ConsentsStatusRes";
import TransactionRes from "../pages/response-pages/transaction/TransactionRes";
import ResponseContent from "../pages/ResponseContent";
import "./Layout.css";
import Header from "../UI/header/Header";
import StatusRequest from "../pages/pisp/request-content/bulk-payment/StatusRequest";
import StatusResponse from "../pages/pisp/response-content/bulk-payment/StatusResponse";
import DetailsResponse from "../pages/pisp/response-content/bulk-payment/DetailsResponse";
import DetailsRequest from "../pages/pisp/request-content/bulk-payment/DetailsRequest";
import PaymentStatusRequest from "../pages/pisp/request-content/payments/status/PaymentStatusRequest";
import PaymentStatusResponse from "../pages/pisp/response-content/payments/status/PaymentStatusResponse";
import DetailsStatusRequest from "../pages/pisp/request-content/payments/details/DetailsStatusRequest";
import DetailsStatusResponse from "../pages/pisp/response-content/payments/details/DetailsStatusResponse";
import PaymentDeleteRequest from "../pages/pisp/request-content/payments/cancel/PaymentDeleteRequest";
import PaymentDeleteResponse from "../pages/pisp/response-content/payments/cancel/PaymentDeleteResponse";
import PaymentsCreateResponse from "../pages/pisp/response-content/payments/create/PaymentsCreateResponse";
import PaymentCreateRequest from "../pages/pisp/request-content/payments/create/PaymentCreateRequest";
import PeriodicStatusRequest from "../pages/pisp/request-content/periodic/status/PeriodicStatusRequest";
import PeriodicStatusResponse from "../pages/pisp/response-content/periodic-response/status/PeriodicStatusResponse";
import PeriodicDetailsRequest from "../pages/pisp/request-content/periodic/details/PeriodicDetailsRequest";
import PeriodicDetailsResponse from "../pages/pisp/response-content/periodic-response/details/PeriodicDetailsResponse";
import PeriodicCancelRequest from "../pages/pisp/request-content/periodic/cancel/PeriodicCancelRequest";
import PeriodicCancelResponse from "../pages/pisp/response-content/periodic-response/cancel/PeriodicCancelResponse";
import ResendOtpRequest from "../pages/authorization/auth-request/resendotp/ResendOtpRequest";
import AuthInitiateRequest from "../pages/authorization/auth-request/initiate/AuthInitiateRequest";
import AuthVerifyRequest from "../pages/authorization/auth-request/verify/AuthVerifyRequest";
import RevokeConsentId from "../pages/authorization/auth-request/revokeconsentid/RevokeConsentId";
import RevokeConsentsReq from "../pages/authorization/auth-request/revokeconsents/RevokeConsentsReq";
import ResendOtpResponse from "../pages/authorization/auth-response/resend-otp/ResendOtpResponse";
import AuthInitiateResponse from "../pages/authorization/auth-response/auth-initiate/AuthInitiateResponse";
import AuthVerifyResponse from "../pages/authorization/auth-response/auth-verify/AuthVerifyResponse";
import ConsentsIdResponse from "../pages/authorization/auth-response/consentsId/ConsentsIdResponse";
import ConsentsResponse from "../pages/authorization/auth-response/consents/ConsentsResponse";
import Errors from "../UI/errors/Errors";
import UsageAndLimits from "../UI/usage-and-limits/UsageAndLimits";
import Glossary from "../UI/glossary/Glossary";
import ConsumeAispPisp from "../pages/description-content/consume-aisp-pisp/ConsumeAispPisp";
import Sandbox from "../pages/description-content/sandbox/Sandbox";

const Layout = () => (
  <Fragment>
    <Grid columns={3} divided>
      <Grid.Column width={4}>
        <div>
          <section className="layout">
            <Header />
            <ContentToLeft />
          </section>
        </div>
      </Grid.Column>
      <Grid.Column width={7}>
        <Description />
        <ObIntegrationGude />
        <RegisterTppPortal />
        <RequestCredentials />
        <ConsumeAispPisp />
        <Sandbox />
        <AuthInitiateRequest />
        <AuthVerifyRequest />
        <ResendOtpRequest />
        <Balance />
        <Transaction />
        <Accounts />
        <AccountIdRequest />
        <ConsentStatusReq />
        <ConsentsGetDetailsReq />
        <StatusRequest />
        <DetailsRequest />
        <PaymentStatusRequest />
        <DetailsStatusRequest />
        <PaymentDeleteRequest />
        <PaymentCreateRequest />
        <PeriodicStatusRequest />
        <PeriodicDetailsRequest />
        <PeriodicCancelRequest />
        <RevokeConsentsReq />
        <RevokeConsentId />
        <Errors />
        <UsageAndLimits />
        <Glossary />
      </Grid.Column>
      <Grid.Column width={5}>
        <div className='bg-black'>
          <ResponseContent />
          <ResponseContent />
          <ResponseContent />
          <ResponseContent />
          <ResponseContent />
          <ResponseContent />
          <AuthInitiateResponse />
          <AuthVerifyResponse />
          <ResendOtpResponse />
          <BalanceRes />
          <TransactionRes />
          <AccountRes />
          <AccountIdRes />
          <ConsentsStatusRes />
          <ConsentGetDetailsRes />
          <StatusResponse />
          <DetailsResponse />
          <PaymentStatusResponse />
          <DetailsStatusResponse />
          <PaymentDeleteResponse />
          <PaymentsCreateResponse />
          <PeriodicStatusResponse />
          <PeriodicDetailsResponse />
          <PeriodicCancelResponse />
          <ConsentsResponse />
          <ConsentsIdResponse />
          <ResponseContent />
          <ResponseContent />
          <ResponseContent />
        </div>
      </Grid.Column>
    </Grid>
  </Fragment>
);

export default Layout;
