
import React, { useState } from 'react'
import { getCookie, Logout } from '../utils/Utils'
import { useJwt } from "react-jwt";

const AuthContext = React.createContext({
    token:'',
    isLoggedIn : false,
    login:(token)=>{},
    logout : ()=>{}
})

const calculateRemainingTime =(expirationTime)=>{
    const currentTime = new Date().getTime()
    const adjExpirationTime = new Date(expirationTime).getTime()

    const remainingDuration = adjExpirationTime - currentTime
    return remainingDuration
}

export const AuthContextProvider = (props) => {
    const initialToken = getCookie("access_token");
    const [token, setToken] = useState(initialToken);
    const { decodedToken, isExpired } = useJwt(token);
    const userIsLoggedIn  = !!decodedToken && !isExpired && !!token;
    
    const logOutHandler = () =>{
        Logout()
        setToken(null)
    }

    const logInHandler = (token,expirationTime) =>{
        setToken(token)
        const remainingTime = calculateRemainingTime(expirationTime)
        setTimeout(logOutHandler,remainingTime);
    }

    const contextValue ={
        token : token,
        isLoggedIn : userIsLoggedIn,
        login: logInHandler,
        logout: logOutHandler
    }
    return <AuthContext.Provider value={contextValue}>{props.children} </AuthContext.Provider>
}

export default AuthContext