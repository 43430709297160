import Card from "./Card"
import { Link } from "react-router-dom"
import classes from './success.module.css'
import Logo from "../../../static/assets/Logo.svg";

const Success = (props) => {
    return (
        <div>
            <div className={classes.logo}>
                <img alt="timer" className="home__logo" src={Logo} />
            </div>
            <div className={classes.wittytitle}>
                <p className={classes.title}>W1TTY Developer</p>
            </div>
            <Card className={classes.card}>
                {!props.error &&
                    <span className={classes.titleSuccess}>Login credentials has been sent to your registered email address. <Link to="./login">Click here to login</Link></span>
                }
                <br></br>
                {props.error && <h1 className={classes.titlerror}>{props.email}, {props.error}</h1>}
                {props.error && <Link to="/" >Go to home page, try registering with alternative email id</Link>}
            </Card>
        </div>
    )
}

export default Success