import { useState } from "react"
import data from "./accountId.json"
import data400 from "./accountId_400.json"
import data500 from "./accountId_500.json"
import JsonResponseOutput from "../../../jsonResponses/JsonResponseOutput"
import "./AccountIdRes.css"

let api = "/api/accounts/{account-id}"

const successJson = <div><pre>{JSON.stringify(data, null, 2)}</pre></div>;
const failureJson = <div><pre>{JSON.stringify(data400, null, 2)}</pre></div>;
const internalServerErrorJson = <div><pre>{JSON.stringify(data500, null, 2)}</pre></div>;


const AccountIdRes = () =>{

    const [statusCode, setStatusCode] = useState('200')

    const successStatusHandler = () => {
        setStatusCode('200')
    }

    const failureStatusHandler = () => {
        setStatusCode('400')
    }

    const internalServerErroStatusHandler = () => {
        setStatusCode('500')
    }

    const requestType = <button className="status200">GET</button>
    return <div id="balanceId" className="right-content">
        <div>
            <span className="textTricks">Request URL: </span>
        </div>
        <button className="but">{requestType} {api}</button>
        <div>
            <span>
                <br></br>
            </span>
            <span className="textTricks">Response -</span>
        </div>
        <div>
            <span></span>
        </div>
        <div className="alignment">
            <button className="status200" onClick={successStatusHandler}>200</button>
            <button className="status400" onClick={failureStatusHandler}>400</button>
            <button className="status500" onClick={internalServerErroStatusHandler}>500</button>
        </div>
        <div>
            {statusCode === '200' && <JsonResponseOutput output={successJson} message={"Transaction Successful"} />}
            {statusCode === '400' && <JsonResponseOutput output={failureJson} message={"Validation Failure"} />}
            {statusCode === '500' && <JsonResponseOutput output={internalServerErrorJson}
                message={"Internal Server Error"} />}
        </div>
    </div>

}

export default AccountIdRes