import "./ResendOtpRequest.css";

let api = "/api/external/v1/obs/resend-otp";

const requestType = <button className="status200">POST</button>;

const ResendOtpRequest = (props) => {
  return (
    <div id="resendotp" className="balance">
      <div>
        <span className="textTricks"> Request parameters: </span>
      </div>
      <button className="but">
        {requestType} {api}
      </button>
      <br />
      <br />
      <div>
        <span className="textFamily">Headers: </span>
      </div>
      <br />
      <table>
        <tbody>
          <tr>
            <th>Header</th>
            <th>Value</th>
          </tr>
          <tr>
            <td>Authorization Code</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Content-Type</td>
            <td>application/json</td>
          </tr>
        </tbody>
      </table>
      <br />
      <div>
        <span className="textFamily">
          REQUEST BODY SCHEMA: application/json
        </span>
      </div>
      <br />
      <table>
        <tbody>
          <tr>
            <th>Parameter</th>
            <th>Description</th>
          </tr>
          <tr>
            <td>otpID</td>
            <td>string</td>
          </tr>
        </tbody>
      </table>
      <br />
      <div>
        <span className="textTricks">Responses</span>
      </div>
      <br />
      <div>
        <span>200 OK</span>
      </div>
      <br />
      <div>
        <span>400 Bad Request</span>
      </div>
      <br />
      <div>
        <span>401 Unauthorized</span>
      </div>
      <br />
      <div>
        <span>500 Internal server error</span>
      </div>
    </div>
  );
};

export default ResendOtpRequest;
