import { useEffect, useState } from "react";
import classes from "./Register.module.css";
import axios from "axios";
import { Constants } from "../../../utils/Constant";
import ConfirmModal from "./ConfirmModal";
import Success from "./Success";
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from 'react-simple-captcha';
import { getFullURL } from "../../../utils/Utils";

const Register = () => {
  const secret = Math.random().toString(36).slice(-10);
  const [enteredName, setEnteredName] = useState(null);
  const [enteredCompany, setEnteredCompany] = useState(null);
  const [enteredEmail, setEnteredEmail] = useState(null);
  const [enteredPhone, setEnteredPhone] = useState(null);
  const [error, setError] = useState(null);
  const [isUserRegistered, setIsUserRegistered] = useState(false);
  const [isModal, setIsModal] = useState(null);
  const [loadSuccess, setLoadSuccess] = useState(false);
  const [captchaInput, setCaptchaInput] = useState(null);

  useEffect(() => {
    loadCaptchaEnginge(6, '#000', '#fff');
  }, [])

  const nameChangeHandler = (event) => {
    setEnteredName(event.target.value);
  };

  const emailChangeHandler = (event) => {
    setEnteredEmail(event.target.value);
  };

  const orgChangeHandler = (event) => {
    setEnteredCompany(event.target.value);
  };

  const phoneChangeHandler = (event) => {
    setEnteredPhone(event.target.value);
  };

  const capchaChangeHandler = (event) => {
    setCaptchaInput(event.target.value);
  };

  const validateAndSubmit = (event) => {
    event.preventDefault();
    const captchaValue = captchaInput;

    if (validateCaptcha(captchaValue) === true) {
      submitHandler();
    }
    else {
      alert('Captcha Does Not Match');
    }
  };

  const submitHandler = () => {
    const url = getFullURL(Constants.API.REGISTER_ACCOUNT)
    axios
      .post(url, {
        name: enteredName,
        emailId: enteredEmail,
        organizationName: enteredCompany,
        secret: secret,
        phoneNo: enteredPhone,
      })
      .then((response) => {
        setIsUserRegistered(true);
        console.log("isUser registered ", isUserRegistered);
        setIsModal(true);
      })
      .catch((error) => {
        let errorMassage = "Something went wrong please try again.";
        setError(errorMassage);
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.localizedMessage
        ) {
          errorMassage = error.response.data.localizedMessage;
        }
        setError(errorMassage + " could not authenticate");
        setIsModal(true);
      });
  };

  const confirmHandler = () => {
    setIsModal(null);
    setLoadSuccess(true);
  };
  return (
    <section>
      {!loadSuccess && (
        <form className={classes.form} onSubmit={validateAndSubmit}>
          {<h1 className={classes.titl}>Company details</h1>}
          {
            <p className={classes.titldescr}>
              Please provide your company details
            </p>
          }

          {
            <div className={classes.controls}>
              <label htmlFor="name">Your Name</label>
              <input type="name" required onChange={nameChangeHandler} autoComplete="off"/>
            </div>
          }
          {
            <div className={classes.controls}>
              <label htmlFor="organization">Company Name</label>
              <input
                type="text"
                name="organizationName"
                required
                onChange={orgChangeHandler}
                autoComplete="off"
              />
            </div>
          }
          {
            <div className={classes.controls}>
              <label htmlFor="email">Company Email</label>
              <input
                type="email"
                name="organizationName"
                required
                onChange={emailChangeHandler}
                autoComplete="off"
              />
            </div>
          }
          {
            <div className={classes.controls}>
              <label htmlFor="phone">Company Phone</label>
              <input
                type="tel"
                name="organizationName"
                required
                onChange={phoneChangeHandler}
                autoComplete="off"
              />
            </div>
          }
          <div className={classes.controls}>
            <div className={classes.captch}>
              <LoadCanvasTemplate />
            </div>
            <div>
              <input
                placeholder="Enter Captcha"
                name="captchaInput"
                type="text"
                required
                onChange={capchaChangeHandler}
                autoComplete="off"
              />
          </div>
          </div>
          {
            <div className={classes.actions}>
              <button>continue</button>
            </div>
          }
        </form>
      )}
      {loadSuccess && <Success error={error} email={enteredEmail} />}
      {isModal && (
        <ConfirmModal email={enteredEmail} onConfirm={confirmHandler} />
      )}
    </section>
  );
};

export default Register;
