import classes from "./RequestCredentials.module.css"
import { Constants } from "../../../../utils/Constant"
import { MdVpnKey } from 'react-icons/md';
import axios from "axios";
import { useState } from "react";
import { getFullURL, getCookie, setHeader } from "../../../../utils/Utils";

const RequestCredentials = () => {

  const url = getFullURL(Constants.API.CREDENTIAL_REQUEST)
  const [errorValid, isErrorValid] = useState(null)
  const [loadSuccess, setLoadSuccess] = useState(false)
  const submitHandler = (event) => {
    event.preventDefault();
    const accessToken = getCookie('access_token');
    setHeader(accessToken);
    axios.post(url, {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        "Authorization": `Bearer ${accessToken}`,
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }).then((response) => {
      console.log('status ', response)
      setLoadSuccess(true)
    }).catch((error) => {
      if (error && error.response && error.response.data && error.response.data.localizedMessage) {
        isErrorValid(error.response.data.localizedMessage)
      }
    });
  }
  return <div id="requestcredid" className="description">

    <h1>Request Credentials </h1>
    <p className="text-content">
      Use below button to send your developed application. Once you succeed with getting authorized by our
      support team, you receive a confirmation email As a partner, your team can request as many accesses
      for developers as it is needed.
    </p>
    <form onSubmit={submitHandler}>
      <div className={classes.control}>
        <label htmlFor='opening-text'>Request Credentials</label>
      </div>
      <div className={classes.actions}>
        <button className={classes.button}><MdVpnKey /> Request for API credentials</button>
      </div>
    </form>
    <div>
      {loadSuccess && <span className={classes.successText}>Your request is successfully received by our Customer Service You will be updated soon. Please check your e-mail box.</span>}
      <br />
      {errorValid && <span className="textTricksError"> {errorValid}</span>}
    </div>
  </div>
}

export default RequestCredentials