import "./Accounts.css";

let api = "/api/accounts";
const requestType = <button className="status200">GET</button>;

const Accounts = (props) => {
  return (
    <div id="accountsId" className="balance">
      <div>
        <span className="textFamily">
          Fetches the list of all available accounts for the specified party.
        </span>
      </div>
      <div>
        <br />
        <span className="textTricks"> Request parameters: </span>
      </div>
      <button className="but">
        {requestType} {api}
      </button>
      <br />
      <br />
      <div>
        <span className="textFamily">Headers: </span>
      </div>
      <br />
      <table>
        <tbody>
          <tr>
            <th>Header</th>
            <th>Value</th>
          </tr>
          <tr>
            <td>Authorization Code</td>
            <td>Basic base64encoded(clientId:clientSecret)</td>
          </tr>
          <tr>
            <td>Content-Type</td>
            <td>application/json</td>
          </tr>
        </tbody>
      </table>
      <br />
      <div>
        <span className="textFamily">
          REQUEST BODY SCHEMA: application/json
        </span>
      </div>
      <br />
      <table>
        <tbody>
          <tr>
            <th>Parameter</th>
            <th>Description</th>
          </tr>
          <tr>
            <td>NA</td>
            <td>NA</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Accounts;
