import axios from "axios";

export const setHeader = (token) => {
    axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }
}
export function http(obj) {
    let response = null;
    try {
        if (obj.method === "get") {
            response = axios.get(obj.url);
        } else {
            response = axios.post(obj.url);
        }
    } catch (error) {
        response = error;
    }
    return response;
}
export const setCookie = (name, value, time) => {
    var expires = "";
    if (time) {
        var date = new Date();
        date.setTime(date.getTime() + time);
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/;secure";
}
export const getCookie = (name) => {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}
export const eraseCookie = (name) => {
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

export const Logout = () => {
    eraseCookie("access_token");
    window.location.href = "/";
}

export const getFullURL = (url) => {
    return url
}