
import classes from "./ConsumeAispPist.module.css"

const ConsumeAispPisp = () => {
    return (
        <div id="consumeaisppisp" className={classes.aisppisp}>
            <div className={classes.heightSpacing}>
                <span className={classes.spanbold}>
                    In order to consume AISP and PISP API, you need to get access and refresh tokens.
                    The flow is as follows.
            </span>
            </div>
            <div className={classes.heightSpacing}>
                <span className={classes.spanboldsub}>
                    -Call POST /obs/auth-initiate
                </span>
            </div>

            <div className={classes.heightSpacing}>
                <span className={classes.spanboldsub}>
                    -Call POST /obs/auth-verify
                </span>
            </div>

            <div className={classes.heightSpacing}>
                <span className={classes.spanboldsub}>
                    -Call POST /digix-auth/v1/token with grant-type AUTHORIZATION_CODE
                </span>
            </div>

            <div className={classes.heightSpacing}>
                <span className={classes.spanboldsub}>
                    Call AISP/PISP endpoints [Each AISP/PISP API request must contain an Authorization header
                    in the following format to make a call: Bearer [access token]]
                </span>
            </div>
            <div className={classes.heightSpacing}>
                <span className={classes.spanboldsub}>
                    In order to revoke consent :
            </span>
            </div>
            <div className={classes.heightSpacing}>
                <span className={classes.spanboldsub}>
                    -Call specific endpoints from Revoke consent endpoints section.
            </span>
            </div>
        </div>

    )
}

export default ConsumeAispPisp