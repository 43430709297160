import { useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import Logo from "../../../static/assets/Logo.svg";
import AuthContext from "../../../store/auth-context";
import "./Home.css"
const Home = () => {
    const authCtx = useContext(AuthContext)
    const history = useHistory()
    const isLoggedIn = authCtx.isLoggedIn
    console.log("isLoggedIn", isLoggedIn)
    if(isLoggedIn){
        history.replace("/layout")
    }
    return (
        <div className="home">
            <img alt="timer" className="home__logo" src={Logo} />
            <p className="title">W1TTY Developer</p>
            <p className="descript">W1TTY Developer</p>
            <div>
                <button className="docs-button"><Link to="/login">Login</Link></button>
                <button className="docs-button"><Link to="/register">Register Account</Link></button>
            </div>
        </div>
    );

}

export default Home