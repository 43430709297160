import classes from "./Login.module.css";
import { Constants } from "../../../utils/Constant";
import { useContext, useState } from "react";
import axios from "axios";
import AuthContext from "../../../store/auth-context";
import { Link, useHistory } from "react-router-dom";
import { setCookie, setHeader, getFullURL } from "../../../utils/Utils";

const Login = (props) => {
  const history = useHistory();
  const [enteredEmail, setEnteredEmail] = useState("");
  const [enteredPassword, setEnteredPassword] = useState("");
  const [error, setError] = useState(null);
  const authCtx = useContext(AuthContext);

  const emailChangeHandler = (event) => {
    setEnteredEmail(event.target.value);
  };

  const passwordChangeHandler = (event) => {
    setEnteredPassword(event.target.value);
  };

  const loginUserHandler = (event) => {
    event.preventDefault();
    const url = getFullURL(Constants.API.LOGIN);
    const varToken = btoa("developer-portal:secret");
    const form = new FormData();
    form.set("username", enteredEmail);
    form.set("password", enteredPassword);
    form.append("grant_type", "password");
    form.append("scope", "read write");
    axios
      .post(url, form, {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: "Basic " + varToken,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((response) => {
        const { access_token, expires_in } = response.data;
        const expirationTime = new Date(
          new Date().getTime() + expires_in * 1000
        );
        authCtx.login(response.data.access_token, expirationTime);
        setHeader(access_token);
        setCookie("access_token", access_token, parseInt(expires_in) * 1000);
        history.replace("/layout");
      })
      .catch((err) => {
        let errorMassage =
          "Something went wrong please enter valid credentials or try again.";
        setError(errorMassage);
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.error === "invalid_grant"
        ) {
          setError("Invalid credentials. Please try again.");
        }

        console.log(err);
      });
  };

  return (
    <div className={classes.main}>
      <h1 className={classes.title}>Welcome to the Developer portal (develop env)</h1>
      <form className={classes.auth} onSubmit={loginUserHandler}>
        <div className={classes.control}>
          <label htmlFor="email">Your Email</label>
          <input
            type="email"
            id="email"
            required
            onChange={emailChangeHandler}
            autoComplete="off"
          />
        </div>
        <div className={classes.control}>
          <label htmlFor="password">Your Password</label>
          <input
            type="password"
            id="password"
            required
            onChange={passwordChangeHandler}
          />
        </div>
        <div className={classes.actions}>
          <button>Log In</button>
          <br />
          <div>
            {!error && (
              <Link to="./register" className={classes.w50}>
                New User? Register acount
              </Link>
            )}
          </div>
        </div>
        <div>{error && <span className={classes.error}>{error}</span>}</div>
      </form>
    </div>
  );
};

export default Login;
